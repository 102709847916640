import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate }  from 'gatsby'
import ds from '../theme'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Body2 from '../components/Body2'
import Div from '../components/Div/Div.styles'
import H3 from '../components/H3'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Subtitle from '../components/SubTitle2'
import Paragraph from '../components/Paragraph'
import { css } from '@emotion/core'

import { Container, mq } from '../components/styles/Layout.styles'
import Button from '../components/Button/Button'
import { 
  styledCertificatesSSL,
  header
} from '../components/styles/CertificatesSSL.styles'
import { 
  currency,
  item
} from '../components/styles/DedicatedServersPrice.styles'

const ProductItem = ({
  productName,
  productAdvantages,
  productPrice,
  productDescription,
  productBtn,
}) => {
  return (
    <div
      style={{
        padding: '24px 16px 40px 16px',
        backgroundColor: '#ffffff',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12,
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H5
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            width: 296,
            textAlign: 'center',
            marginTop: 20
          }}
        >
          {productName}
        </H5>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '40px 0 20px 0'
          }}
        />
        <H3
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 8,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <span css={currency}>R$</span>
          {productPrice}
          <span css={currency}>/Ano</span>
        </H3>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '20px 0 40px 0'
          }}
        />
        <Body2 
          style={{
            fontSize: 14, 
            marginLeft: 24,
            marginRight: 24,
            height: 360,
            textAlign: 'left',
            lineHeight: 2.5,
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.87)',
            whiteSpace: 'pre-wrap'
          }}>
          {productDescription}
        </Body2>
        <Button
          onClick={() => navigate('/pedido-de-certificado-ssl')}
          schema='white'
          outlined
          selected
          variant='base'
          on='onSurface'
          width={['100%']}
          maxWidth={['140px']}
          style={{
            display: 'flex',
            border: '1px solid rgba(0, 0, 0, 0.12)'
          }}
        >
          {productBtn}
        </Button>
      </Div>
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledCertificatesSSL}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
            mb={[30, 30, 50, 50]}
          >
            <Column
              width={[1]}
            >
              <H4>
                <span>Certificado SSL</span>
                Proteção e Confiança
              </H4>
              <Subtitle>Proteja o seu site e conquiste a confiança dos visitantes com os Certificados SSL.</Subtitle>
              <Paragraph>Atualmente, a HostDime disponibiliza 5 tipos de certificados SSL para suas necessidades. Através de nossa parceria com a Comodo, nós oferecemos certificados SSL 128-Bit e 256-Bit padrão para manter a segurança de seu domínio. Também oferecemos um certificado Wild Card que pode garantir a segurança de todos os seus subdomínios.</Paragraph>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
            mt={30}
          >
            <Column
              mb={[16, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='Essencial SSL'
                  productPrice='139,00'
                  productDescription={`R$ 139,00 por ano\nDomínio Validado\nReedições Ilimitadas\nCertificado SSL 2048 bits\nReembolso de 30 dias\nGarantia $10,000\n99,3% de Compatibilidade do Navegador\nCriptografia de 128/256 bits`}
                  productBtn='ASSINE'
                />
              </Div>
            </Column>
            <Column
              mb={[16, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='Comodo SSL'
                  productPrice='199,00'
                  productDescription={`Domínio Validado\nEmissão Rápida\nReedições Ilimitadas\nCertificado SSL 2048 bits\nReembolso de 30 dias\nGarantia $250,000\n99,3% de Compatibilidade do Navegador\nCriptografia de 128/256 bits`}
                  productBtn='ASSINE'
                />
              </Div>
            </Column>
            <Column
              mb={[16, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='Essential SSL Wildcard'
                  productPrice='699,00'
                  productDescription={`Domínio Validado\nEmissão Rápida\nReedições Ilimitadas\nCertificado SSL 2048 bits\nReembolso de 30 dias\nGarantia $10,000\n99,3% de Compatibilidade do Navegador\nCriptografia de 128/256 bits`}
                  productBtn='ASSINE'
                />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
          >
            <Column
              mb={[16, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='Instant SSl (OV)'
                  productPrice='399,00'
                  productDescription={`Reedições Ilimitadas\nCertificado SSL 2048 bits\nReembolso de 30 dias\nGarantia $10,000\n99,3% de Compatibilidade do Navegador\nCriptografia de 128/256 bits`}
                  productBtn='ASSINE'
                />
              </Div>
            </Column>
            <Column
              mb={[16, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='EV SSL'
                  productPrice='1.399,00'
                  productDescription={`Certificado SSL 2048 bits\nReembolso de 30 dias\nGarantia $10,000\n99,3% de Compatibilidade do Navegador\nCriptografia de 128/256 bits\nTrustLogo Dinâmico\nCompleta Validação\nPermite Green Bar`}
                  productBtn='ASSINE'
                />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent='center'
            mt={[14, 6]}
          >
            <Column>
              <Subtitle>*Não instalamos SSL fora da rede HostDime</Subtitle>                  
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Certificados SSL' />
      <PageTemplate
        title='Certificados SSL'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
