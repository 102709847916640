import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledCertificatesSSLBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50, 50, 70, 70]
  })
)

export const styledCertificatesSSL = css`
  ${styledCertificatesSSLBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [8],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [16],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const contentListBps = css(
  mq({
    marginLeft: [0, 89, 118, 220],
    marginRight: [0, 89, 106, 160]
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const sslItemsBps = css(
  mq({
    marginLeft: [0, 136, 24, 126],
    marginRight: [0, 136, 24, 126]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  p {
    ${PHeaderBps};
  }
`
export const sslItems = css`
  display: flex;
  margin: 0 auto;
  justify-content: center;
`

export const sslItem = css`
  max-width: 328px;
  height: 666px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  margin-bottom: 16px;
  h3 {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    justify-content: center;
    margin: 22.5px 16px;
    width: 296px;
    font-weight: 500;
    span {
      font-size: 13.8px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.74;
      letter-spacing: 0.1px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  h5 {
    color: rgba(0, 0, 0, 0.87);
    width: 296px;
    text-align: center;
    margin: 40px 16px;
  }
`

export const line = css`
  width: 50px;
  height: 1px;
  border-bottom: solid 4px #f3f5f5;
  margin: 0 auto;
`

export const content = css`
  width: 280px;
  margin: 39.5px 24px;
  p {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 16px;
    line-height: 1.29;
    :nth-last-child(1) {
      margin-bottom: 0;
    }
  }
`

export const evSSL = css`
  width: 280px;
  margin: 39.5px 24px;
  p {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 16px;
    line-height: 1.29;
    :nth-last-child(1) {
      margin-bottom: 0;
    }
  }
`